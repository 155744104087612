<template>
  <div v-loading="form.isLoading" class="ComposeEmail">
    <div class="p-a-s">
      <div class="is-flex is-aligned-middle">
        <div class="m-r-a">
          <v-icon type="envelope" />
          <span class="has-text-weight-semibold is-uppercase">{{ $t('forms.email') }}</span>
        </div>
        <v-button
          class="is-dark is-plain"
          data-testid="email-cancel-button"
          @click.prevent="goBack"
        >
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button
          class="is-primary"
          data-testid="email-send-button"
          @click.prevent="submit"
        >
          {{ $t('forms.send') }}
        </v-button>
      </div>
      <div class="is-flex is-aligned-middle border-b-black m-b-s">
        <div class="has-text-weight-bold">
          {{ $t('forms.send_to') }}
        </div>
        <form-field
          :validator="$v.form.recipients"
          class="is-expanded"
        >
          <input
            v-model="form.recipients"
            :placeholder="$t('pages.emails.compose.select_relations')"
            data-testid="email-send-to"
            readonly
            class="input is-transparent"
            type="text"
          >
        </form-field>
      </div>
      <div class="is-flex is-aligned-middle border-b-black m-b-s">
        <div class="has-text-weight-bold">
          {{ $t('forms.subject') }}
        </div>
        <form-field
          :validator="$v.form.subject"
          class="is-expanded"
        >
          <input
            v-model="form.subject"
            class="input is-transparent"
            data-testid="email-subject"
            name="subject"
            type="text"
          >
        </form-field>
      </div>
    </div>
    <div
      v-loading="isLoading"
      class="ComposeEmail__body"
    >
      <form-field :validator="$v.form.body">
        <froala-wrapper
          v-model="form.body"
          :config="bodyEditorConfig"
          class="content"
          data-testid="email-content"
          name="signature"
          rows="10"
          tabindex="0"
          @hook:created="registerFroalaButtons"
        />
      </form-field>
    </div>
  </div>
</template>

<script>
import Form from '@/services/forms/Form'

import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

function createForm () {
  return new Form({
    type: { value: 'activity-message' },
    recipients: { value: [], rules: { required } },
    subject: { value: '', rules: { required } },
    body: { value: '' },
    scheduling_config: {
      value: {
        status: false,
        time: null,
        date: null,
        timezone: null
      }
    }
  })
}

export default {
  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isLoading: false,
      form: createForm(),
      bodyEditorConfig: {
        placeholderText: this.$t('pages.emails.compose.email_body'),
        pluginsEnabled: ['quickInsert', 'paragraphFormat', 'link', 'lists'],
        toolbarButtons: ['bold', 'italic', 'paragraphFormat', 'insertLink', 'formatOL', 'formatUL', 'recipient_name_placeholder', 'recipient_company_placeholder'],
        paragraphFormat: {
          N: 'Normal',
          H3: 'Heading'
        },
        paragraphFormatSelection: true,
        linkAlwaysBlank: true,
        linkAlwaysNoFollow: false,
        toolbarBottom: true,
        linkEditButtons: ['linkOpen'],
        linkInsertButtons: [],
        linkList: [],
        heightMin: 300
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    ...mapGetters(['userEmailSettings'])
  },

  mounted () {
    this.setupEmailFormForNewEmail()
  },

  beforeRouteUpdate (to, from, next) {
    this.form.reset()
    this.$v.form.$reset()
    next()
  },

  methods: {
    ...mapActions('connect/relations/activities/organiser', ['setComponent']),

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      this.form.recipients = [this.relation.id] // must be an array for the api, and the uuid not the name
      this.form.post(`/brands/${this.activeBrandId}/emails`)
        .then(() => {
          this.$notify.success({
            title: this.$t('general.success'),
            duration: 5000,
            message: this.$t('success.email_sent_successfully')
          })
          this.$store.dispatch('connect/relations/activities/organiser/setComponent', 'Start')
          this.$emit('change')
        })
        .catch(error => {
          this.$displayRequestError(error)
        })

      this.form.recipients = this.relation.preferred_name
    },

    appendSignature () {
      const signature = this.userEmailSettings.signature
      if (signature) {
        this.form.body += `--<br/> ${signature}`
      }
    },

    registerFroalaButtons () {
      /* eslint-disable no-undef */
      $.FroalaEditor.DefineIcon('recipient_name_placeholder', { NAME: 'user' })
      $.FroalaEditor.RegisterCommand('recipient_name_placeholder', {
        title: this.$t('pages.emails.compose.recipient_name_placeholder_title'),
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback () {
          this.html.insert('[[RECIPIENT_NAME]]')
        }
      })

      // $.FroalaEditor.DefineIcon('recipient_company_placeholder', { NAME: 'building' })
      // $.FroalaEditor.RegisterCommand('recipient_company_placeholder', {
      //   title: this.$t('pages.emails.compose.recipient_company_placeholder_title'),
      //   focus: false,
      //   undo: true,
      //   refreshAfterCallback: true,
      //   callback () {
      //     this.html.insert('[[RECIPIENT_COMPANY]]')
      //   }
      // })
    },

    setupEmailFormForNewEmail () {
      this.appendSignature()

      this.form.recipients = this.relation.preferred_name
    },

    async goBack () {
      try {
        await this.$confirm(this.$t('warnings.confirm_email_create_cancel'))
        this.setComponent('Start')
      } catch (e) {
        this.$displayRequestError(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

</style>
