<template>
  <div class="activity_organiser_wrapper p-t-m">
    <relation-activity-organiser :relation="relation" />
  </div>
</template>

<script>
import RelationActivityOrganiser from '@/components/connect/relations/organiser/RelationActivityOrganiser'

export default {
  components: {
    RelationActivityOrganiser
  },

  props: {
    relation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.activity_organiser_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
