<template>
  <timeline-item icon="notes">
    <template #header>
      <span class="m-r-s m-l-s">
        {{ datetime(item.created_at) }}
      </span>

      <span class="m-l-m m-r-s">
        {{ `${$t('pages.relations.activity.timeline.created_by')} ${item.user_full_name}` }}
      </span>

      <span>
        <span class="has-text-weight-bold m-l-m m-r-s">
          {{ $t('pages.relations.activity.timeline.title') }}:
        </span>
        {{ item.title }}
      </span>
    </template>

    <template #content>
      <span v-html="item.description" />
    </template>

    <template #actions>
      <v-icon
        type="pencil"
        class="red-icon is-cursor-pointer"
        data-testid="notes-edit-icon"
        @click="editClicked"
      />

      <v-icon
        type="trash"
        class="red-icon is-cursor-pointer"
        data-testid="notes-trash-icon"
        @click="deleteClicked"
      />
    </template>
  </timeline-item>
</template>

<script>
import { mapActions } from 'vuex'

import TimelineItem from './TimelineItem'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

export default {
  name: 'NotesTimelineItem',

  components: {
    TimelineItem
  },

  mixins: [DatetimeMixin],

  props: {
    item: {
      type: Object,
      required: true
    },
    relation: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('connect/relations/activities/organiser', [
      'setComponent',
      'setOriginatingItem'
    ]),

    async deleteClicked () {
      try {
        await this.$confirm(this.$t('pages.relations.activity.timeline.delete_warning_notes'))

        await this.$api.delete(`/connect/relations/${this.relation.id}/activity/notes/${this.item.id}`)

        this.$emit('delete', this.item)

        this.$notify.success(this.$t('pages.relations.activity.timeline.note_deleted'))
      } catch (e) {
        if (e === 'cancel') return

        return this.$displayRequestError(e)
      }
    },

    editClicked () {
      this.setOriginatingItem(this.item)
      this.setComponent('')
        .then(() => {
          setTimeout(() => {
            this.setComponent('Notes')
          }, 100)
        })
    }
  }
}
</script>
