import Api from './Api'

export const ActivityNotesApi = {
  /**
   * call the backend API to save this note and let the caller know
   * requires an options object containing title, description, relationId and brandId
   * @param {Object} params
   * @param {string} params.relationId
   * @param {string} params.brandId
   * @param {string} params.title
   * @param {string} params.description
   */
  create (params) {
    return Api.post(`/connect/relations/${params.relationId}/activity/notes`, {
      brand: params.brandId,
      title: params.title,
      description: params.description
    })
  },

  /**
   * call the backend API to update the given note note and let the caller know
   * requires an options object containing title, description, relationId and brandId
   * @param {string} noteId
   * @param {Object} params
   * @param {string} params.relationId
   * @param {string} params.brandId
   * @param {string} params.title
   * @param {string} params.description
   */
  update (noteId, params) {
    return Api.patch(`/connect/relations/${params.relationId}/activity/notes/${noteId}`, {
      brand: params.brandId,
      title: params.title,
      description: params.description
    })
  }
}
