<template>
  <div class="start-wrapper aligned-middle">
    <div>
      <div class="start-text has-text-weight-semibold p-b-m is-size-5">
        {{ $t('pages.relations.activity.start.title') }}
      </div>

      <div class="buttons is-aligned-center">
        <v-button
          v-for="button in buttons"
          :key="button.name"
          :class="`${button.name}-btn`"
          :data-testid="`${button.name}-btn`"
          :disabled="button.disabled"
          class="is-black is-small"
          @click="setComponent(button.name)"
        >
          {{ $t(`pages.relations.activity.start.${button.name}`) }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      buttons: [
        { name: 'notes', disabled: false },
        // { name: 'tasks', disabled: true },
        { name: 'email', disabled: false }
      ]
    }
  },

  methods: {
    ...mapActions('connect/relations/activities/organiser', [
      'setComponent'
    ])
  }
}
</script>

<style lang="scss">
@import "~utils";

.start-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
</style>
