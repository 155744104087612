<template>
  <timeline-item icon="email">
    <template #header>
      <span class="m-h-s">
        {{ datetime(createdAt, 'DD/MM/YYYY - HH:mm') }}
      </span>|
      <span>
        {{ `${sender} ${messageType} ` }}
        <span>{{ recipient }}</span>
      </span>
    </template>
    <template #content>
      <span class="has-text-weight-bold">
        {{ $t('pages.relations.activity.timeline.subject') }}:
      </span>
      <span>
        {{ item.subject }}
      </span>
      <v-separator dark />
      <span v-html="item.body" />
    </template>
    <template #icons>
      <el-tooltip
        effect="light"
        placement="top"
      >
        <div slot="content">
          {{ `${$t('pages.relations.activity.timeline.read')}: ${item.unread ? $t('pages.relations.activity.timeline.no'):
            $t('pages.relations.activity.timeline.yes')}` }}
        </div>
        <span>
          <v-icon
            type="question-circle"
            class="red-icon"
          />
        </span>
      </el-tooltip>
      <v-icon
        type="trash"
        class="red-icon is-cursor-pointer"
        @click="deleteClicked"
      />
      <v-icon
        type="forward"
        class="flip-vertically disabled-icon"
      />
    </template>
  </timeline-item>
</template>

<script>
import TimelineItem from './TimelineItem'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

export default {
  name: 'EmailRepliesTimelineItem',

  components: {
    TimelineItem
  },

  mixins: [DatetimeMixin],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    createdAt () {
      return this.item.date * 1000
    },

    sender () {
      return this.item.from[0].name
    },

    messageType () {
      if (this.item.headers['In-Reply-To']) {
        return this.$t('pages.relations.activity.timeline.replied_to')
      }
      return this.$t('pages.relations.activity.timeline.sent_to')
    },

    recipient () {
      return this.item.to[0].name
    }
  },

  methods: {
    async deleteClicked () {
      try {
        await this.$confirm(this.$t('pages.relations.activity.timeline.delete_warning'))
        await this.$api.delete(`/emails/${this.item.id}`)
        this.$emit('deleted', this.item)
      } catch (e) {
        if (e === 'cancel') return

        return this.$displayRequestError(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.flip-vertically {
  transform: scaleX(-1);
}

.disabled-icon {
  color: $grey-light;
}
</style>
