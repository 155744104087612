<template>
  <div class="timeline-wrapper">
    <!--<search-filters />-->
    <div v-loading="loading" class="timeline-list">
      <component
        :is="item.type"
        v-for="(item, index) in timeLineItems"
        :key="index"
        :item="item"
        :relation="relation"
        @delete="fetchFreshItems"
      />

      <div
        v-if="!timeLineItems.length"
        class="has-text-centered"
      >
        {{ $t('general.no_more_items') }}
      </div>
    </div>
  </div>
</template>

<script>
import Email from './EmailTimelineItem'
import Replies from './EmailRepliesTimelineItem'
import Notes from './NotesTimelineItem'

export default {
  name: 'RelationActivityOrganiserTimeline',

  components: {
    Email,
    Replies,
    Notes
  },

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      timeLineItems: [],
      loading: false
    }
  },

  mounted () {
    this.fetchTimeline()

    this.syncInterval = setInterval(() => this.fetchTimeline, 30000)
  },

  beforeDestroy () {
    clearInterval(this.syncInterval)
  },

  methods: {
    fetchTimeline () {
      this.loading = true

      const promise1 = this.$api.getData(`/brands/${this.activeBrandId}/emails`, {
        params: {
          type: 'activity-message',
          sort: 'newest',
          include: ['recipients', 'sender'],
          relation: this.relation.id
        }
      })
        .then((emails) => {
          this.addToItems(emails, 'Email')
        })

      const promise2 = this.$api.getData(`/connect/relations/${this.relation.id}/activity/notes`, {
        params: {
          brand: this.activeBrandId,
          sort: 'newest'
        }
      })
        .then(notes => {
          this.addToItems(notes, 'Notes')
        })

      Promise.all([promise1, promise2]).catch((error) => {
        this.$displayRequestError(error)
      }).finally(() => {
        this.loading = false
      })
    },

    addToItems (data, type) {
      if (data.length < 1) return

      const result = data.filter(item => !this.hasItem(item))

      this.timeLineItems = this.timeLineItems.concat(result.map(item => {
        item.type = type

        return item
      }))
    },

    hasItem (item) {
      return !!this.timeLineItems.find((tlItem) => {
        return tlItem.id === item.id
      })
    },

    fetchFreshItems () {
      this.timeLineItems = []

      this.fetchTimeline()
    }
  }
}
</script>
