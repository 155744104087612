<template>
  <div
    v-loading="loading"
    class="notes-wrapper"
  >
    <div class="p-a-s">
      <div class="is-flex is-aligned-middle m-b-m">
        <div class="m-r-a" data-testid="ActivityNoteTitle">
          <v-icon type="pencil" />
          <span>
            {{ toolbarTitle }}
          </span>
        </div>

        <v-button
          class="is-black is-plain"
          data-testid="Cancel_btn"
          @click="resetEvents"
        >
          {{ $t('pages.relations.activity.shared.cancel') }}
        </v-button>

        <v-button
          class="is-primary"
          data-testid="Save_btn"
          @click="onSave"
        >
          {{ $t('pages.relations.activity.shared.save') }}
        </v-button>
      </div>

      <div class="is-flex is-aligned-middle border-b-black">
        <div class="has-text-weight-bold">
          {{ $t('pages.relations.activity.notes.title_word') }}
        </div>

        <form-field
          :validator="$v.form.title"
          :attribute="$t('pages.relations.activity.notes.title_word')"
          class="is-expanded"
        >
          <input
            ref="title"
            v-model="form.title"
            :placeholder="$t('pages.relations.activity.notes.title')"
            type="text"
            class="input is-transparent"
            data-testid="Form_title"
            @input="$v.form.title.$touch()"
          >
        </form-field>
      </div>
    </div>

    <form-field :attribute="$t('pages.relations.activity.notes.description_attribute')">
      <froala-wrapper
        v-model="form.contents"
        :config="copytext.config"
        class="content"
        name="copytext"
        rows="10"
        required
        tabindex="0"
        data-testid="Form_input"
      />
    </form-field>
  </div>
</template>

<script>
import { ActivityNotesApi } from '@/services/RelationActivityApiService'

import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RelationActivityOrganiserNotes',

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      copytext: {
        config: {
          placeholderText: this.$t('pages.relations.activity.notes.content_placeholder'),
          pluginsEnabled: ['quickInsert', 'paragraphFormat', 'link', 'lists', 'colors', 'file'],
          toolbarBottom: true,
          toolbarButtons: ['bold', 'italic', 'underline', 'color', 'insertFile'],
          paragraphFormat: {
            N: 'Normal',
            H3: 'Heading'
          },
          paragraphFormatSelection: true,
          linkAlwaysBlank: true,
          linkAlwaysNoFollow: false,
          linkEditButtons: ['linkEdit', 'linkRemove'],
          linkInsertButtons: [],
          linkList: [],
          heightMin: 195
        }
      },
      form: {
        title: '',
        contents: ''
      },

      loading: false,
      noteId: 0,
      isEditing: false
    }
  },

  validations: {
    form: {
      title: { required, minLength: minLength(5) }
    }
  },

  computed: {
    ...mapState('connect/relations/activities/organiser', [
      'originalItem'
    ]),

    toolbarTitle () {
      return this.isEditing
        ? this.$t('pages.relations.activity.notes.header.edit')
        : this.$t('pages.relations.activity.notes.header.add')
    },

    hasNoteToEdit () {
      return this.originalItem.description
    }
  },

  mounted () {
    if (this.hasNoteToEdit) {
      this.isEditing = true
      this.noteId = this.originalItem.id
      this.form.title = this.originalItem.title
      this.form.contents = this.originalItem.description
      this.setOriginatingItem({})
    }
  },

  methods: {
    ...mapActions('connect/relations/activities/organiser', [
      'resetEvents',
      'setOriginatingItem'
    ]),

    async onSave () {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.loading = true
      const params = {
        relationId: this.relation.id,
        brand: this.activeBrandId,
        title: this.form.title,
        description: this.form.contents || undefined
      }

      try {
        if (!this.isEditing) {
          await ActivityNotesApi.create(params)
          this.$notify.success(this.$t('success.note_created'))
        } else {
          await ActivityNotesApi.update(this.noteId, params)
          this.$notify.success(this.$t('success.note_edited'))
        }
        this.$v.form.$reset()
        this.form.title = ''
        this.form.contents = ''
        this.$emit('change')
        this.resetEvents()
      } catch (e) {
        this.$notify.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
