<template>
  <div class="is-flex is-aligned-middle m-b-l">
    <div class="is-narrow p-r-s">
      <slot name="type-icon">
        <v-icon :type="icon" size="2x" />
      </slot>
    </div>

    <div
      class="Card is-expanded is-full-height has-shadow-intense"
      data-testid="timeline-card"
    >
      <div class="Card__header has-background is-flex">
        <div class="header-area">
          <slot name="header" /> <!-- title area !-->
        </div>
        <div class="m-l-a icons is-flex no-wrap is-aligned-middle">
          <slot name="actions" />
        </div>
      </div>
      <div
        :class="{'collapsed': isCollapsed}"
        class="expandedContent Card__body"
      >
        <slot
          name="content"
          class="content-area"
        />
        <div
          class="has-text-primary has-text-right expand-text is-cursor-pointer"
          @click="expandClicked"
        >
          <v-icon :type="isCollapsed ? 'caret-down': 'caret-up'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineItem',

  props: {
    icon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isCollapsed: true
    }
  },

  methods: {
    expandClicked () {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.Card {
  position: relative;

  .expandedContent {
    min-height: 8rem;
  }

  .expand-text {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2000;
    background-image: radial-gradient(circle at top left, transparent, white);
    @include rtl {
      right: auto;
      left: 0;
    }
  }

  .collapsed {
    height: 8rem;
    overflow: hidden;

    .expand-text {
      width: 100%;
      padding-top: 4rem;
    }
  }
}
</style>
