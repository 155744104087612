<template>
  <div class="organiser is-full-width">
    <component
      :is="activeComponent"
      :relation="relation"
      class="has-shadow-intense m-b-xl"
      @change="handleChange"
    />

    <relation-activity-organiser-timeline
      ref="timeline"
      :relation="relation"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Notes from './notes/RelationActivityOrganiserNotes'
import Email from './email/RelationActivityOrganiserEmail'

import Start from './RelationActivityOrganiserStart'
import RelationActivityOrganiserTimeline from './timeline/RelationActivityOrganiserTimeline'

export default {
  components: {
    Start,
    Notes,
    Email,
    RelationActivityOrganiserTimeline
  },

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('connect/relations/activities/organiser', { activeComponent: 'component' })
  },

  beforeDestroy () {
    this.setComponent('Start')
  },

  methods: {
    ...mapActions('connect/relations/activities/organiser', [
      'setComponent'
    ]),

    handleChange () {
      this.$refs.timeline.fetchFreshItems()
    }
  }
}
</script>
