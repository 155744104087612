<template>
  <timeline-item icon="email">
    <template #header>
      <span class="m-h-s">
        {{ datetime(item.created_at, 'DD/MM/YYYY - HH:mm') }}
      </span>|
      <span>
        {{ `${sender} ${messageType} ` }}
        <el-tooltip
          v-if="hasMultipleRecipients"
          effect="light"
          placement="top"
        >
          <div slot="content">
            <span
              v-for="(recipient, index) in recipientsList"
              :key="index"
            >
              {{ recipient.name }}
              <br>
            </span>
          </div>
          <span class="is-cursor-pointer has-text-decoration-underline">{{ recipients }}</span>
        </el-tooltip>
        <span v-else>{{ recipients }}</span>
      </span>
    </template>
    <template #content>
      <span class="has-text-weight-bold">
        {{ $t('pages.relations.activity.timeline.subject') }}:
      </span>
      <span>
        {{ item.subject }}
      </span>
      <v-separator dark fat />
      <span v-html="item.body" />
    </template>
    <template #actions>
      <el-tooltip
        effect="light"
        placement="top"
      >
        <div slot="content">
          {{ `${$t('pages.relations.facts.press_email_opened')}: ${totalRecipientsOpened}/${totalRecipients}` }}<br>
          {{ `${$t('pages.relations.facts.press_email_clicked')}: ${totalRecipientsClicked}/${totalRecipients}` }}
        </div>
        <span>
          <v-icon
            type="question-circle"
            class="red-icon"
          />
        </span>
      </el-tooltip>
      <v-icon
        type="trash"
        class="red-icon is-cursor-pointer"
        @click="deleteClicked"
      />
      <v-icon
        type="forward"
        class="flip-vertically disabled-icon"
      />
    </template>
  </timeline-item>
</template>

<script>
import TimelineItem from './TimelineItem'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

export default {
  name: 'EmailTimelineItem',

  components: {
    TimelineItem
  },

  mixins: [DatetimeMixin],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    sender () {
      return this.item.sender.data.full_name
    },

    hasMultipleRecipients () {
      return this.item.recipients.data.length > 1
    },

    messageType () {
      return this.$t('pages.relations.activity.timeline.sent_to')
    },

    recipientsList () {
      return this.item.recipients.data
    },

    recipients () {
      let name = ''
      if (this.item.recipients.data[0]) {
        name = this.item.recipients.data[0].name
      }
      return this.hasMultipleRecipients
        ? this.$t('pages.relations.activity.timeline.multiple_recipients')
        : name
    },

    status () {
      let status = this.$t('general.not_available')
      if (this.totalRecipientsOpened === this.item.recipients.data.length) { // everyone has opened it
        status = this.$t('pages.relations.activity.timeline.opened')
      } else if (this.totalRecipientsOpened > 0 && this.totalRecipientsOpened < this.item.recipients.data.length) {
        status = this.$t('pages.relations.activity.timeline.partly_opened')
      } else {
        status = this.$t('pages.relations.activity.timeline.unopened')
      }

      return status
    },

    totalRecipients () {
      return this.item.recipients.data.length
    },

    totalRecipientsOpened () {
      let count = 0
      this.item.recipients.data.forEach((recipient) => {
        if (recipient.opened_email) {
          count += 1
        }
      })

      return count
    },

    totalRecipientsClicked () {
      let count = 0
      this.item.recipients.data.forEach((recipient) => {
        if (recipient.opened_link) {
          count += 1
        }
      })

      return count
    }
  },

  methods: {
    getTitle () {
      return `${this.sender} ${this.messageType} ${this.recipients}`
    },

    async deleteClicked () {
      try {
        await this.$confirm(this.$t('pages.relations.activity.timeline.delete_warning'))
        await this.$api.delete(`/emails/${this.item.id}`)
        this.$emit('delete', this.item)
      } catch (e) {
        if (e === 'cancel') return

        return this.$displayRequestError(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.flip-vertically {
  transform: scaleX(-1);
}

.disabled-icon {
  color: $grey-light;
}
</style>
